import React from "react"
import { useTranslation } from "react-i18next"

import Api from "../../../images/API Integration.png"
import Section from "../../Section/Section"
import DayraApp from "../../../images/mobile.png"
import PartnerApp from "../../../images/Partner App.png"

import "./Integration.scss"

export default function Integration() {
  const { t } = useTranslation()

  return (
    <div className="integration text-left">
      <Section>
        <h2 id="integration">{t("Integration.title")}</h2>
        <h3>{t("Integration.description")}</h3>
        <div className="integration-options">
          <div className="integration-details">
            <div className="content-container">
              <h4>{t("Integration.subtitle_1")}</h4>
              <p>{t("Integration.description_1")}</p>
            </div>
            <img
              src={PartnerApp}
              width="150"
              height="165"
              className="integration"
              alt="A mobile phone with the Dayra app on it"
            ></img>
          </div>

          <div className="integration-details">
            <div className="content-container">
              <h4>{t("Integration.subtitle_2")}</h4>
              <p>{t("Integration.description_2")}</p>
            </div>
            <img
              src={DayraApp}
              width="150"
              height="165"
              alt="A mobile phone with the Dayra app on it"
            ></img>
          </div>
        </div>
      </Section>

      <Section>
        <h2>{t("Integration.api_title")}</h2>
        <h3>
          {t("Integration.api_subtitle_1")} <br></br>
          {t("Integration.api_subtitle_2")}
        </h3>
        <p>{t("Integration.api_description")}</p>
        <img
          src={Api}
          width="562"
          height="239"
          alt="A black laptop screen with a few colorful lines of code"
          className="integration-2"
        ></img>
      </Section>
    </div>
  )
}
